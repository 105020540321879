@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.html-inject {
    &.margins-enabled {
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            margin-top: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 21px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 31px;
        }
    }
}
